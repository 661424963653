import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AboutSection from "./components/About";
import CounterSection from "./components/Counter";
import ExhibitorsSection from "./components/Exhibitors";
import HeroSection from "./components/Hero";
import PartnersSection from "./components/Partners";
import ScheduleSection from "./components/Schedule";
import SpeakersSection from "./components/Speakers";
import SponsorsSection from "./components/Sponsors";
import TicketsSection from "./components/Tickets";
import Login from "./components/Login";
import WhatsApp from "./components/WhatsAppChat";
import Dashboard from "./components/admin/Dashboard";
import Footer from "./components/Footer";
import CommitteeSection from "./components/Committee";
import Information from "./components/Information";

function App() {
  return (
    <Router>
      <Routes>
        {/* Main Event Sections */}
        <Route
          path="/"
          element={
            <div className="app">
              <div className="scroll-container">
                <HeroSection />
                <AboutSection />
                <SpeakersSection />
                <SponsorsSection />
                <ExhibitorsSection />
                <ScheduleSection />
                <CounterSection />
                <TicketsSection />
                <PartnersSection />
                <CommitteeSection />
                <Information />
                <Footer />
              </div>
            </div>
          }
        />
        {/* Checkout Page */}
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
      <WhatsApp />
    </Router>
  );
}

export default App;
