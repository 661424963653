import React, { useState, useEffect } from "react";
import { Table, Button, Input, Upload, message, Popconfirm } from "antd";
import { db, collection, addDoc } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { deleteDoc, doc, getDocs, updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid'; // To generate unique image IDs

type Speaker = {
  key: string;
  name: string;
  role: string;
  company: string;
  shortBio: string;
  fullBio: string;
  imageUrl: string;
  timestamp: any;
};

const ManageSpeakers: React.FC = () => {
  const navigate = useNavigate();
  const [speakers, setSpeakers] = useState<Speaker[]>([]);
  const [newSpeaker, setNewSpeaker] = useState<Speaker>({
    key: "",
    name: "",
    role: "",
    company: "",
    shortBio: "",
    fullBio: "",
    imageUrl: "",
    timestamp: new Date(),
  });

  useEffect(() => {
    // Check if the user is authenticated
    const isAuthenticated = localStorage.getItem("isAuthenticated");

    if (!isAuthenticated) {
      // If not authenticated, redirect to the login page
      navigate("/login");
    }
  }, [navigate]);

  const fetchSpeakers = async () => {
    const querySnapshot = await getDocs(collection(db, "speakers"));
    const speakersData: Speaker[] = querySnapshot.docs.map((doc) => ({
      key: doc.id,
      name: doc.data().name,
      role: doc.data().role,
      company: doc.data().company,
      shortBio: doc.data().shortBio,
      fullBio: doc.data().fullBio,
      imageUrl: doc.data().imageUrl,
      timestamp: doc.data().timestamp.toDate(),
    }));
    setSpeakers(speakersData);
  };

  const handleAddSpeaker = async () => {
    if (!newSpeaker.name || !newSpeaker.role || !newSpeaker.company) {
      message.error("Please fill out all the fields!");
      return;
    }
    try {
      await addDoc(collection(db, "speakers"), {
        ...newSpeaker,
      });

      setNewSpeaker({
        key: "",
        name: "",
        role: "",
        company: "",
        shortBio: "",
        fullBio: "",
        imageUrl: "",
        timestamp: new Date(),
      });
      fetchSpeakers(); // Refresh the speakers list
    } catch (error) {
      console.error("Error adding speaker:", error);
    }
  };

    const handleEditSpeaker= async (record: Speaker) => {
        setNewSpeaker({
          key: record.key,
          name: record.name,
          role: record.role,
          company: record.company,
          shortBio: record.shortBio,
          fullBio: record.fullBio,
          imageUrl: record.imageUrl,
          timestamp: record.timestamp,
        })    
      }
    
      const handleUpdateSpeaker= async () => {
        try {
            const speakerRef = doc(db, "speakers", newSpeaker.key);
            await updateDoc(speakerRef, newSpeaker);
            setNewSpeaker({
              key: "",
              name: "",
              role: "",
              company: "",
              shortBio: "",
              fullBio: "",
              imageUrl: "",
              timestamp: new Date(),
            });
              fetchSpeakers(); // Refresh list
            } catch (error) {
              console.error("Error updating publish status:", error);
              message.error("Failed to update publish status.");
           }
      }
  
    const handleDeleteSpeaker = async (key: string) => {
      try {
        await deleteDoc(doc(db, "speakers", key));
        message.success("Speaker deleted successfully!");
        fetchSpeakers(); // Refresh Speakers list
      } catch (error) {
        console.error("Error deleting Speaker:", error);
        message.error("Failed to delete Speaker.");
      }
    };


  useEffect(() => {
    fetchSpeakers();
  }, []);

  const speakerColumns = [
    {
      title: "Image",
      key: "imageUrl",
      render: (record: Speaker) => <img src={record.imageUrl} alt="Speaker" style={{ width: 400, height: 100 }} />,
    },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Role", dataIndex: "role", key: "role" },
    { title: "Company", dataIndex: "company", key: "company" },
    { title: "Short Bio", dataIndex: "shortBio", key: "shortBio" },
    { title: "Full Bio", dataIndex: "fullBio", key: "fullBio" },
    {
      title: "Actions",
      key: "actions",
      render: (record: Speaker) => (
        <>
          <Button type="link" onClick={() => handleEditSpeaker(record)}>
            Edit
          </Button>
          <Popconfirm title="Are you sure?" onConfirm={() => handleDeleteSpeaker(record.key)} okText="Yes" cancelText="No">
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <h1 className="title" style={{ color: "#2e3c52" }}>
        Manage Speakers
      </h1>
      <br />
      <div style={{ marginBottom: "20px" }}>
        <Input
          placeholder="Name"
          value={newSpeaker.name}
          onChange={(e) => setNewSpeaker({ ...newSpeaker, name: e.target.value })}
          style={{ marginBottom: "10px", color: "#000" }}
        />
        <Input
          placeholder="Role"
          value={newSpeaker.role}
          onChange={(e) => setNewSpeaker({ ...newSpeaker, role: e.target.value })}
          style={{ marginBottom: "10px", color: "#000" }}
        />
        <Input
          placeholder="Company"
          value={newSpeaker.company}
          onChange={(e) => setNewSpeaker({ ...newSpeaker, company: e.target.value })}
          style={{ marginBottom: "10px", color: "#000" }}
        />
        <Input
          placeholder="Image Url (Cloudinary)"
          value={newSpeaker.imageUrl}
          onChange={(e) => setNewSpeaker({ ...newSpeaker, imageUrl: e.target.value })}
          style={{ marginBottom: "10px", color: "#000" }}
        />
        <Input.TextArea
          placeholder="Short Bio"
          value={newSpeaker.shortBio}
          onChange={(e) => setNewSpeaker({ ...newSpeaker, shortBio: e.target.value })}
          style={{ marginBottom: "10px" }}
        />
        <Input.TextArea
          placeholder="Full Bio"
          value={newSpeaker.fullBio}
          onChange={(e) => setNewSpeaker({ ...newSpeaker, fullBio: e.target.value })}
          style={{ marginBottom: "10px" }}
        />
          {newSpeaker.key ? <Button className="btn btn-register mb-5" onClick={handleUpdateSpeaker} style={{ marginTop: "10px" }}>Update Speaker</Button>:<Button className="btn btn-register mb-5" onClick={handleAddSpeaker} style={{ marginTop: "10px" }}> Add Speaker</Button> }
        
        {/* <Button className="btn btn-register mb-5" onClick={handleAddSpeaker} style={{ marginTop: "10px" }}>
          Add Speaker
        </Button> */}
      </div>
      <Table
        columns={speakerColumns}
        dataSource={speakers}
        pagination={{ pageSize: 5 }}
        bordered
      />
    </>
  );
};

export default ManageSpeakers;
