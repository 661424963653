import React, { useEffect, useState } from "react";
import "./information.css";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebaseConfig";

const Information = () => {
  const [hotels, setHotels] = useState<any>([]);
  const [travels, setTravels] = useState<any>([]);
  const [faqs, setFAQs] = useState<any>([]);
  const [isPublished, setIsPublished] = useState<boolean>(false); // To store the publish status

  useEffect(() => {
    // Fetch the publish setting for "Accommodation & Travel"
    const fetchPublishSetting = async () => {
      try {
        const settingsSnapshot = await getDocs(collection(db, "settings"));
        settingsSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          setIsPublished(data.publishInformation || false);
        });
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchPublishSetting();
  }, []);

  useEffect(() => {
    if (isPublished) {
      // Fetch accommodation and travel data if published
      const fetchAccommodationAndTravel = async () => {
        try {
          const querySnapshot = await getDocs(
            collection(db, "accommodation_and_travel")
          );
          const data = querySnapshot.docs
            .map((doc) => doc.data())
            .filter((item) => item.published);

          setHotels(data.filter((item) => item.type === "hotel"));
          setTravels(data.filter((item) => item.type === "travel"));
        } catch (error) {
          console.error("Error fetching accommodation and travel data:", error);
        }
      };


      const fetchFAQs = async () => {
        try {
          const querySnapshot = await getDocs(
            collection(db, "faqs")
          );
          const data = querySnapshot.docs
            .map((doc) => doc.data());

          setFAQs(data);
        } catch (error) {
          console.error("Error fetching accommodation and travel data:", error);
        }
      };

      fetchAccommodationAndTravel();
      fetchFAQs();
    }
  }, [isPublished]); // Re-fetch if publish status changes

  // FAQs data (for the section below)
//   const faqs = [
//     {
//       question: "What is web marketing ?",
//       answer:
//         "Web marketing refers to a broad category of advertising that takes many different forms, but generally involves any marketing activity conducted online. Marketers have shifted their efforts online because it tends to be significantly less expensive.",
//     },
//     {
//       question: "What is digital advertising ?",
//       answer:
//         "Digital advertising, also called Internet advertising ('Internet marketing') is when businesses leverage Internet technologies to deliver promotional advertisements to consumers.",
//     },
//     {
//       question: "What is Internet marketing ?",
//       answer:
//         "Internet marketing, or online marketing, refers to advertising and marketing efforts that use the Web and email to drive direct sales via electronic commerce, in addition to sales leads from Web sites or emails. Internet marketing and online advertising efforts are typically used in conjunction with traditional.",
//     },
//   ];

  if (!isPublished) {
    // If accommodation and travel is not published, show nothing or a message
    return <></>;
  }

  return (
    <div id="information" className="information"  style={{ borderBottom: "1px solid rgba(230, 167, 59, 0.6)" }}>
      <div className="contact-info">
        <div className="box-img">
          {/* <img src="https://images.pexels.com/photos/2705756/pexels-photo-2705756.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" /> */}
        </div>
        <div className="contact">
          <div className="row">
            <div>
              <h1>Contact Info</h1>
              <p>
                Dear guests, you are most welcomed to join us at Events
                conference. Have a pleasant experience.
              </p>
            </div>
            <div>
              <p>3805 Edwards Rd </p>
              <p>Cincinnati, OH 45209 USA</p>
              <br />
              <p> Phone: (513) 352-3209</p>
              <p>Fax: (513) 352-6220</p>
              <p>Email : contact@events.com</p>
            </div>
          </div>
        </div>
      </div>
      <div className="dark">
        <div style={{ borderBottom: "1px solid rgba(230, 167, 59, 0.6)" }}>
          <div className="row">
            <div className="col-sm-8 col-sm-offset-2 text-center">
              <h1 className="title">Accommodation & Travel</h1>
              <p className="sub-title-3 mt-10">
                Accommodation and travel info - information below or email us
                for further help.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="card-container">
              {hotels.map((hotel: any, index: any) => (
                <div key={index} className="card">
                    <div className="card-img">
                        <img src={hotel.imageUrl} alt="card-img" />
                    </div>
                  <div className="card-content">
                    <p className="sub-title-3 pt-20">{hotel.name}</p>
                    <p className="card-description">{hotel.location}</p>
                    <p className="card-description">{hotel.description}</p>
                  </div>
                   {/* <div className="row">
                      <div className="card-container">
                           {hotels.map((hotel) => (
                              <>
                              <div className="card">
                                <div className="card-img">
                                    <img src={hotel.img} alt="card-image" />
                                </div>
                                  <div className="card-content">
                                     <p>{hotel.name}</p>
                                      <p className="card-description">{hotel.address}</p>
                                      <p className="card-description">{hotel.city}</p>
                                      <p className="card-description">{hotel.tel}</p>
                                   </div>
                            </div>
                                </>
                           ))}
                       </div>
                 </div> */}
                 {/* <div className="row">
                     <div className="card-container">
                          {transpo.map((trans) => (
                             <>
                             <div className="card">
                             <div className="card-img">
                                 <img src={trans.img} alt="card-image" />
                             </div>
                                  <div className="card-content">
                                     <p>{trans.name}</p>
                                      <p className="card-description">{trans.description}</p>
                                    </div>
                                </div>
                             </>
                           ))}
                     </div>
                 </div> */}

                </div>
              ))}

            </div>
          </div>
          <div className="row">
            <div className="card-container">
              {travels.map((travel: any, index: any) => (
                <div key={index} className="card">
                    <div className="card-img">
                        <img src={travel.imageUrl} alt="card-img" />
                    </div>
                  <div className="card-content">
                    <h2 className="sub-title-1 pt-20">{travel.name}</h2>
                    <p className="card-description">{travel.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2 text-center">
            <h1 className="title">Frequently asked questions</h1>
            <p className="sub-title-3 mt-10">
              Some frequently asked questions for you.
            </p>
          </div>
          <div className="faq-list">
            {faqs.map((faq: any, index: any) => (
              <div key={index}>
                <div className="faq-question">
                  <h3>{faq.question}</h3>
                </div>
                <div className="faq-answer">
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center pt-60">
            <h1 className="title">Didn’t find what you're looking for?</h1>
            <p className="sub-title-3 mt-10" style={{ color: "#e6a73b" }}>
              icadubai@uaefma.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
