import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Checkbox } from "antd";
import { db, collection } from "../../firebaseConfig";
import { getDocs, orderBy, query } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import { FaPrint } from "react-icons/fa";
import PrintBadges from "./PrintBadges";

export type Participants = {
  name: string;
  email: string;
  organization: string;
  position: string;
  mobileNumber: string;
  ticketType: string;
};

type Registration = {
  key: string;
  totalAmount: string;
  totalDelegateTickets: string;
  totalUaefmaTickets: string;
  coupon: string;
  timestamp: string;
  contactPersons: Participants[];
};

const ManageRegistrations: React.FC = () => {
  const navigate = useNavigate();
  const [registrations, setRegistrations] = useState<Registration[]>([]);

  useEffect(() => {
    if (!localStorage.getItem("isAuthenticated")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    fetchRegistrations();
    const intervalId = setInterval(fetchRegistrations, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const fetchRegistrations = async () => {
    try {
      const ordersRef = collection(db, "orders");
      const q = query(ordersRef, orderBy("timestamp", "desc"));
      const orderSnapshot = await getDocs(q);

      const ordersWithContacts: any = await Promise.all(
        orderSnapshot.docs.map(async (orderDoc) => {
          const orderData = orderDoc.data();
          const orderId = orderDoc.id;

          const timestamp = orderData.timestamp?.toDate
            ? orderData.timestamp.toDate().toLocaleString()
            : "N/A";

          const contactsRef = collection(db, `orders/${orderId}/contacts`);
          const contactsSnapshot = await getDocs(contactsRef);

          const contactPersons = contactsSnapshot.empty
            ? [{ name: "N/A", email: "N/A", organization: "N/A" }]
            : contactsSnapshot.docs.map((contactDoc) => ({
                name: contactDoc.data().name || "N/A",
                email: contactDoc.data().email || "N/A",
                organization: contactDoc.data().organization || "N/A",
                position: contactDoc.data().position || "N/A",
                mobileNumber: contactDoc.data().mobileNumber || "N/A",
                ticketType: contactDoc.data().ticketType || "N/A",
              }));

          return {
            key: orderId,
            totalAmount: orderData.totalAmount || "0.00",
            totalDelegateTickets: orderData.totalDelegateTickets || "0",
            totalUaefmaTickets: orderData.totalUaefmaTickets || "0",
            coupon: orderData.coupon || "None",
            timestamp,
            contactPersons,
          };
        })
      );

      setRegistrations(ordersWithContacts);
    } catch (error) {
      console.error("Error fetching registrations:", error);
    }
  };

  const handlePrintBadges = (record: Registration) => {
    setCurrentContacts(record.contactPersons);
    setSelectedContacts(record.contactPersons.map((p) => p)); // Select all by default
    setIsModalVisible(true);
  };

  const orderColumns = [
    { title: "Date of Registration", dataIndex: "timestamp", key: "timestamp" },
    { title: "Total Amount", dataIndex: "totalAmount", key: "totalAmount" },
    {
      title: "Delegate Tickets",
      dataIndex: "totalDelegateTickets",
      key: "totalDelegateTickets",
    },
    {
      title: "UAEFMA Member Tickets",
      dataIndex: "totalUaefmaTickets",
      key: "totalUaefmaTickets",
    },
    { title: "Coupon", dataIndex: "coupon", key: "coupon" },
    {
      title: "Print",
      key: "actions",
      render: (_: any, record: Registration) => (
        <Button
          onClick={() => handlePrintBadges(record)}
          className="bg-white border border-gray-400 text-gray-700 rounded-lg w-10 h-10 flex items-center justify-center hover:bg-gray-100"
        >
          <FaPrint size={18} />
        </Button>
      ),
    },
  ];

  const [selectedContacts, setSelectedContacts] = useState<Participants[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentContacts, setCurrentContacts] = useState<Participants[]>([]);

  const handleSelect = (contact: any) => {
    setSelectedContacts((prev) =>
      prev.some((c) => c.email === contact.email)
        ? prev.filter((c) => c.email !== contact.email)
        : [...prev, contact]
    );
  };

  const [isPrintMode, setIsPrintMode] = useState(false);
  const handlePrintSelected = () => {
    setIsPrintMode(true); // Show the print layout
    // Delay printing to allow the component to render.
    setTimeout(() => {
      window.print();
      setIsPrintMode(false); // Reset after printing
      setIsModalVisible(false);
    }, 500);
  };

  const badgeColumns = [
    {
      title: "",
      key: "actions",
      width: 50,
      render: (_: any, contact: any) => (
        <Checkbox
          checked={selectedContacts.includes(contact)}
          onChange={() => handleSelect(contact)}
        />
      ),
    },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Type", dataIndex: "ticketType", key: "ticketType" },
  ];

  return (
    <>
      <h1 className="title" style={{ color: "#2e3c52" }}>
        Registrations
      </h1>
      <br />
      <Table
        columns={orderColumns}
        dataSource={registrations}
        pagination={{ pageSize: 12 }}
        bordered
        expandable={{
          expandedRowRender: (record) => (
            <Table
              columns={[
                { title: "Name", dataIndex: "name", key: "name" },
                { title: "Type", dataIndex: "ticketType", key: "ticketType" },
                { title: "Email", dataIndex: "email", key: "email" },
                {
                  title: "Organization",
                  dataIndex: "organization",
                  key: "organization",
                },
                {
                  title: "Position",
                  dataIndex: "position",
                  key: "position",
                },
                {
                  title: "Contact Number",
                  dataIndex: "mobileNumber",
                  key: "mobileNumber",
                },
              ]}
              dataSource={record.contactPersons}
              pagination={false}
              rowKey={(record) => record.email} // Unique key for contact persons
            />
          ),
          rowExpandable: (record) => record.contactPersons.length > 0,
        }}
      />

      {/* Print Modal */}
      <Modal
        title="Print Badges"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={
          <Button
            type="primary"
            onClick={handlePrintSelected}
            disabled={selectedContacts.length === 0}
          >
            {selectedContacts.length > 1
              ? `Print Selected (${selectedContacts.length})`
              : `Print Selected`}
          </Button>
        }
      >
        <div className="grid grid-cols-2 gap-4">
          <Table
            columns={badgeColumns}
            dataSource={currentContacts}
            pagination={{ pageSize: 5 }}
            bordered
          />
        </div>

        {isPrintMode && (
          <div className="print-only">
            <PrintBadges contacts={selectedContacts} eventName={"ICa"} />
          </div>
        )}
      </Modal>
    </>
  );
};

export default ManageRegistrations;
