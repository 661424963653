import React, { useState, useEffect } from "react";
import { Table, Button, Input, Upload, message, Popconfirm } from "antd";
import { db, collection, addDoc } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { doc, getDocs, updateDoc, deleteDoc} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid"; // To generate unique image IDs

type Committee = {
  key: string;
  name: string;
  role: string;
  company: string;
  imageUrl: string;
  timestamp: any;
};

const ManageCommittees: React.FC = () => {
  const navigate = useNavigate();
  const [committees, setCommittees] = useState<Committee[]>([]);
  const [newCommittee, setNewCommittee] = useState<Committee>({
    key: "",
    name: "",
    role: "",
    company: "",
    imageUrl: "",
    timestamp: new Date(),
  });

  useEffect(() => {
    // Check if the user is authenticated
    const isAuthenticated = localStorage.getItem("isAuthenticated");

    if (!isAuthenticated) {
      // If not authenticated, redirect to the login page
      navigate("/login");
    }
  }, [navigate]);

  const fetchCommittees = async () => {
    const querySnapshot = await getDocs(collection(db, "committees"));
    const committeesData: Committee[] = querySnapshot.docs.map((doc) => ({
      key: doc.id,
      name: doc.data().name,
      role: doc.data().role,
      company: doc.data().company,
      imageUrl: doc.data().imageUrl,
      timestamp: doc.data().timestamp.toDate(),
    }));
    setCommittees(committeesData);
  };

  const handleAddCommittee = async () => {
    if (!newCommittee.name || !newCommittee.role || !newCommittee.company) {
      message.error("Please fill out all the fields!");
      return;
    }
    try {
      await addDoc(collection(db, "committees"), {
        ...newCommittee,
      });

      setNewCommittee({
        key: "",
        name: "",
        role: "",
        company: "",
        imageUrl: "",
        timestamp: new Date(),
      });
      fetchCommittees(); // Refresh the committees list
    } catch (error) {
      console.error("Error adding committee:", error);
    }
  };

  const handleEditCommittee = async (record: Committee) => {
    setNewCommittee({
      key: record.key,
      name: record.name,
      role: record.role,
      company: record.company,
      imageUrl: record.imageUrl,
      timestamp: record.timestamp,
    })    
  }

  const handleUpdateACommittee = async () => {
    try {
        const committeeRef = doc(db, "committees", newCommittee.key);
        await updateDoc(committeeRef, newCommittee);
        setNewCommittee({
          key: "",
          name: "",
          role: "",
          company: "",
          imageUrl: "",
          timestamp: new Date(),
        });
          fetchCommittees(); // Refresh list
        } catch (error) {
          console.error("Error updating publish status:", error);
          message.error("Failed to update publish status.");
        }
  }

  const handleDeleteCommittee = async (key: string) => {
     try {
          await deleteDoc(doc(db, "committees", key));
          message.success("Committee deleted successfully!");
          fetchCommittees(); // Refresh list
        } catch (error) {
          console.error("Error deleting committee:", error);
          message.error("Failed to delete committee.");
        }
  }

  useEffect(() => {
    fetchCommittees();
  }, []);

  const committeeColumns = [
    {
      title: "Image",
      key: "imageUrl",
      render: (record: Committee) => (
        <img
          src={record.imageUrl}
          alt="Committee"
          style={{ width: 80, height: 100 }}
        />
      ),
    },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Role", dataIndex: "role", key: "role" },
    {
      title: "Actions",
      key: "actions",
      render: (record: Committee) => (
        <>
          <Button type="link" onClick={() => handleEditCommittee(record)}>
            Edit
          </Button>
          <Popconfirm title="Are you sure?" onConfirm={() => handleDeleteCommittee(record.key)} okText="Yes" cancelText="No">
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <h1 className="title" style={{ color: "#2e3c52" }}>
        Manage Committees
      </h1>
      <br />
      <div style={{ marginBottom: "20px" }}>
        <Input
          placeholder="Name"
          value={newCommittee.name}
          onChange={(e) =>
            setNewCommittee({ ...newCommittee, name: e.target.value })
          }
          style={{ marginBottom: "10px", color: "#000" }}
        />
        <Input
          placeholder="Role"
          value={newCommittee.role}
          onChange={(e) =>
            setNewCommittee({ ...newCommittee, role: e.target.value })
          }
          style={{ marginBottom: "10px", color: "#000" }}
        />
        <Input
          placeholder="Company"
          value={newCommittee.company}
          onChange={(e) =>
            setNewCommittee({ ...newCommittee, company: e.target.value })
          }
          style={{ marginBottom: "10px", color: "#000" }}
        />
        <Input
          placeholder="Image Url (Cloudinary)"
          value={newCommittee.imageUrl}
          onChange={(e) =>
            setNewCommittee({ ...newCommittee, imageUrl: e.target.value })
          }
          style={{ marginBottom: "10px", color: "#000" }}
        />
        {newCommittee.key ? <Button className="btn btn-register mb-5" onClick={handleUpdateACommittee} style={{ marginTop: "10px" }}>Update Committee</Button>:<Button className="btn btn-register mb-5" onClick={handleAddCommittee} style={{ marginTop: "10px" }}>Add Committee</Button> }
        
        {/* <Button
          className="btn btn-register mb-5"
          onClick={handleAddCommittee}
          style={{ marginTop: "10px" }}
        >
          Add Committee
        </Button> */}
      </div>
      <Table
        columns={committeeColumns}
        dataSource={committees}
        pagination={{ pageSize: 5 }}
        bordered
      />
    </>
  );
};

export default ManageCommittees;
