import { getDocs, collection } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { db } from "../firebaseConfig";

const ExhibitorsSection = () => {
  const [showAll, setShowAll] = useState(false);

  const exhibitors = [
    {
      id: 1,
      logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735461631/horizontal_logo_kkwqdx.svg",
      alt: "Exhibitor 1",
    },
    {
      id: 2,
      logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735461631/horizontal_logo_kkwqdx.svg",
      alt: "Exhibitor 2",
    },
    {
      id: 3,
      logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735461631/horizontal_logo_kkwqdx.svg",
      alt: "Exhibitor 3",
    },
    {
      id: 4,
      logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735461631/horizontal_logo_kkwqdx.svg",
      alt: "Exhibitor 4",
    },
    {
      id: 5,
      logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735461631/horizontal_logo_kkwqdx.svg",
      alt: "Exhibitor 5",
    },
    {
      id: 6,
      logo: "https://res.cloudinary.com/astcdubai/image/upload/v1735461631/horizontal_logo_kkwqdx.svg",
      alt: "Exhibitor 6",
    },
  ];

  const visibleExhibitors = showAll ? exhibitors : exhibitors.slice(0, 3);

  const [isPublished, setIsPublished] = useState<boolean>(false); // To store the publish status

  useEffect(() => {
    // Fetch the publish setting for "Sponsors"
    const fetchPublishSetting = async () => {
      try {
        const settingsSnapshot = await getDocs(collection(db, "settings"));
        settingsSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          setIsPublished(data.publishSponsors || false);
        });
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchPublishSetting();
  }, []);
  
  if (!isPublished) {
    // If accommodation and travel is not published, show nothing or a message
    return <></>;
  }
  
  return (
    <section id="sponsors" className="speakers pt-120 brd-bottom">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 col-sm-offset-2 mb-100 text-center">
            <h1 className="title">Exhibitors</h1>
          </div>
        </div>
        <div className="row">
          <TransitionGroup className="sponsor-row">
            {visibleExhibitors.map((exhibitor) => (
              <CSSTransition key={exhibitor.id} timeout={500} classNames="fade">
                <div className="col-md-3 col-sm-3 sponsor">
                  <img src={exhibitor.logo} alt={exhibitor.alt} />
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
        <div className="row text-center mb-60 mt-40">
          <h3 className="sub-title-2">
            {!showAll ? "View all exhibitors" : ""}
          </h3>
          <div
            className={`btn-primary ${showAll ? "view-less" : ""}`}
            onClick={() => setShowAll(!showAll)}
          >
            <span className={`arrow ${showAll ? "up" : "down"}`}></span>
          </div>
          <h3 className="sub-title-2">{!showAll ? "" : "View less"}</h3>
        </div>
      </div>
    </section>
  );
};

export default ExhibitorsSection;
