// src/pages/ManageFAQs.tsx

import React, { useState, useEffect } from "react";
import { Table, Button, Input, message, Popconfirm } from "antd";
import { db, collection, addDoc } from "../../firebaseConfig";
import { deleteDoc, doc, getDocs, updateDoc } from "@firebase/firestore";

type FAQ = {
  key: string;
  question: string;
  answer: string;
};

const ManageFAQs: React.FC = () => {
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [newFAQ, setNewFAQ] = useState<FAQ>({
    key: "",
    question: "",
    answer: "",
  });

  useEffect(() => {
    fetchFAQs(); // Fetch FAQs when component mounts
  }, []);

  const fetchFAQs = async () => {
    const querySnapshot = await getDocs(collection(db, "faqs"));
    const data: FAQ[] = querySnapshot.docs.map((doc) => {
      const docData = doc.data();
      return {
        key: doc.id,
        question: docData.question,
        answer: docData.answer,
      };
    });
    setFaqs(data);
  };

  const handleAddFAQ = async () => {
    if (!newFAQ.question || !newFAQ.answer) {
      message.error("Please fill out both fields!");
      return;
    }
    try {
      await addDoc(collection(db, "faqs"), {
        ...newFAQ,
      });
      setNewFAQ({ key: "", question: "", answer: "" });
      fetchFAQs(); // Refresh FAQ list
    } catch (error) {
      console.error("Error adding FAQ:", error);
    }
  };

  const handleEditFAQ= async (record: FAQ) => {
      setNewFAQ({
        key: record.key,
        question: record.question,
        answer: record.answer,
      })    
    }
  
    const handleUpdateFAQ= async () => {
      try {
          const faqRef = doc(db, "faqs", newFAQ.key);
          await updateDoc(faqRef, newFAQ);
          setNewFAQ({
            key: "",
            question: "",
            answer: "",
          });
            fetchFAQs(); // Refresh list
          } catch (error) {
            console.error("Error updating publish status:", error);
            message.error("Failed to update publish status.");
         }
    }

  const handleDeleteFAQ = async (key: string) => {
    try {
      await deleteDoc(doc(db, "faqs", key));
      message.success("FAQ deleted successfully!");
      fetchFAQs(); // Refresh FAQ list
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      message.error("Failed to delete FAQ.");
    }
  };

  const faqColumns = [
    { title: "Question", dataIndex: "question", key: "question" },
    { title: "Answer", dataIndex: "answer", key: "answer" },
    {
      title: "Actions",
      key: "actions",
      render: (record: FAQ) => (
        <>
          <Button type="link" onClick={() => handleEditFAQ(record)}>
            Edit
          </Button>
          <Popconfirm title="Are you sure?" onConfirm={() => handleDeleteFAQ(record.key)} okText="Yes" cancelText="No">
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </>
        // <Popconfirm
        //   title="Are you sure?"
        //   onConfirm={() => handleDeleteFAQ(record.key)}
        //   okText="Yes"
        //   cancelText="No"
        // >
        //   <Button type="link" danger>
        //     Delete
        //   </Button>
        // </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <h1 className="title" style={{ color: "#2e3c52" }}>
        Manage FAQs
      </h1>
      {/* FAQ Form */}
      <div className="pt-20" style={{ marginBottom: "20px" }}>
        <Input
          placeholder="Question"
          value={newFAQ.question}
          onChange={(e) => setNewFAQ({ ...newFAQ, question: e.target.value })}
          style={{ marginBottom: "10px", color: "#000" }}
        />
        <Input
          placeholder="Answer"
          value={newFAQ.answer}
          onChange={(e) => setNewFAQ({ ...newFAQ, answer: e.target.value })}
          style={{ marginBottom: "10px", color: "#000" }}
        />
        {newFAQ.key ?  <Button className="btn btn-register mb-5" onClick={handleUpdateFAQ} style={{ marginTop: "10px" }}>Update FAQ</Button>:<Button className="btn btn-register mb-5" onClick={handleAddFAQ} style={{ marginTop: "10px" }}> Add FAQ</Button> }
        
        {/* <Button
          className="btn btn-register mb-5"
          onClick={handleAddFAQ}
          style={{ marginTop: "10px" }}
        >
          Add FAQ
        </Button> */}
      </div>
      {/* FAQ Table */}
      <Table
        columns={faqColumns}
        dataSource={faqs}
        pagination={{ pageSize: 5 }}
        bordered
      />
    </>
  );
};

export default ManageFAQs;
