import React, { useState, useEffect } from "react";
import { Table, Button, Input, message, Switch, Popconfirm, Select } from "antd";
import { db, collection, addDoc } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { deleteDoc, doc, getDocs, updateDoc } from "@firebase/firestore";

type AccommodationAndTravel = {
  key: string;
  type: string;
  name: string;
  location: string;
  description: string;
  imageUrl: string;
  timestamp: any;
  published: boolean;
};

const ManageAccommodationAndTravel: React.FC = () => {
  const navigate = useNavigate();
  const [accommodations, setAccommodations] = useState<AccommodationAndTravel[]>([]);
  const [newAccommodation, setNewAccommodation] = useState<AccommodationAndTravel>({
    key: "",
    type: "",
    name: "",
    location: "",
    description: "",
    imageUrl: "",
    timestamp: new Date(),
    published: false,
  });

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (!isAuthenticated) {
      navigate("/login");
    }
  
  }, [navigate]);

  const fetchAccommodations = async () => {
    const querySnapshot = await getDocs(collection(db, "accommodation_and_travel"));
    const data: AccommodationAndTravel[] = querySnapshot.docs.map((doc) => {
      const docData = doc.data();
      return {
        key: doc.id,
        type: docData.type,
        name: docData.name,
        location: docData.location,
        description: docData.description,
        imageUrl: docData.imageUrl,
        timestamp: docData.timestamp.toDate(),
        published: docData.published,
      };
    });
    setAccommodations(data);
  };

  const handleAddAccommodation = async () => {
    if (!newAccommodation.type || !newAccommodation.name || !newAccommodation.location) {
      message.error("Please fill out all fields!");
      return;
    }
    try {
      await addDoc(collection(db, "accommodation_and_travel"), {
        ...newAccommodation,
      });
      setNewAccommodation({
        key: "",
        type: "",
        name: "",
        location: "",
        description: "",
        imageUrl: "",
        timestamp: new Date(),
        published: false,
      });
      fetchAccommodations(); // Refresh list
    } catch (error) {
      console.error("Error adding accommodation:", error);
    }
  };

  const togglePublishStatus = async (record: AccommodationAndTravel) => {
    try {
      const accommodationRef = doc(db, "accommodation_and_travel", record.key);
      await updateDoc(accommodationRef, { published: !record.published });
      message.success(`Accommodation ${!record.published ? "published" : "unpublished"} successfully!`);
      fetchAccommodations(); // Refresh list
    } catch (error) {
      console.error("Error updating publish status:", error);
      message.error("Failed to update publish status.");
    }

  };

  const handleEditAccommodation = async (record: AccommodationAndTravel) => {
   setNewAccommodation({
      key: record.key,
      type: record.type,
      name: record.name,
      location: record.location,
      description: record.description,
      imageUrl: record.imageUrl,
      timestamp: record.timestamp,
      published: record.published,
    });
  };

  // update accommodation
  const handleUpdateAccommodation = async () => {
    try {
      const accommodationRef = doc(db, "accommodation_and_travel", newAccommodation.key);
      await updateDoc(accommodationRef, newAccommodation);
      setNewAccommodation({
        key: "",
        type: "",
        name: "",
        location: "",
        description: "",
        imageUrl: "",
        timestamp: new Date(),
        published: false,
      });
      fetchAccommodations(); // Refresh list
    } catch (error) {
      console.error("Error updating publish status:", error);
      message.error("Failed to update publish status.");
    }
  }


  const handleDeleteAccommodation = async (key: string) => {
    try {
      await deleteDoc(doc(db, "accommodation_and_travel", key));
      message.success("Accommodation deleted successfully!");
      fetchAccommodations(); // Refresh list
    } catch (error) {
      console.error("Error deleting accommodation:", error);
      message.error("Failed to delete accommodation.");
    }
  };

  useEffect(() => {
    fetchAccommodations();
  }, []);

  const accommodationColumns = [
    {
      title: "Image",
      key: "imageUrl",
      render: (record: AccommodationAndTravel) => <img src={record.imageUrl} alt="Accommodation" style={{ width: 100, height: 100 }} />,
    },
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Location", dataIndex: "location", key: "location" },
    { title: "Description", dataIndex: "description", key: "description" },
    {
      title: "Published",
      key: "published",
      render: (record: AccommodationAndTravel) => (
        <Switch checked={record.published} onChange={() => togglePublishStatus(record)} />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: AccommodationAndTravel) => (
        <>
          <Button type="link" onClick={() => handleEditAccommodation(record)}>
            Edit
          </Button>
          <Popconfirm title="Are you sure?" onConfirm={() => handleDeleteAccommodation(record.key)} okText="Yes" cancelText="No">
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <h1 className="title" style={{ color: "#2e3c52" }}>Manage Accommodation & Travel</h1>
      <div className="pt-20" style={{ marginBottom: "20px" }}>
        <Select
          placeholder="Select Type  (e.g hotel, travel) "
          value={newAccommodation.type}
          onChange={(value) => setNewAccommodation({ ...newAccommodation, type: value })}
          defaultValue="hotel"
          style={{ marginBottom: "10px", width: "30%" }}
        >
          <Select.Option value="hotel">Hotel</Select.Option>
          <Select.Option value="travel">Travel</Select.Option>
        </Select>
        <Input
          placeholder="Name"
          value={newAccommodation.name}
          onChange={(e) => setNewAccommodation({ ...newAccommodation, name: e.target.value })}
          style={{ marginBottom: "10px", color: "#000" }}
        />
        <Input
          placeholder="Location"
          value={newAccommodation.location}
          onChange={(e) => setNewAccommodation({ ...newAccommodation, location: e.target.value })}
          style={{ marginBottom: "10px", color: "#000" }}
        />
        <Input
          placeholder="Description"
          value={newAccommodation.description}
          onChange={(e) => setNewAccommodation({ ...newAccommodation, description: e.target.value })}
          style={{ marginBottom: "10px", color: "#000" }}
        />
        <Input
          placeholder="Image Url"
          value={newAccommodation.imageUrl}
          onChange={(e) => setNewAccommodation({ ...newAccommodation, imageUrl: e.target.value })}
          style={{ marginBottom: "10px", color: "#000" }}
        />
          {newAccommodation.key ?  <Button className="btn btn-register mb-5" onClick={handleUpdateAccommodation} style={{ marginTop: "10px" }}>Update Accommodation</Button>:<Button className="btn btn-register mb-5" onClick={handleAddAccommodation} style={{ marginTop: "10px" }}> Add Accommodation</Button> }
      </div>
      <Table columns={accommodationColumns} dataSource={accommodations} pagination={{ pageSize: 5 }} bordered />
    </>
  );
};

export default ManageAccommodationAndTravel;
