// PrintBadges.tsx
import React from "react";
import "./printbadges.css";
import { Participants } from "./ManageRegistrations";

interface PrintBadgesProps {
  contacts: Participants[];
  eventName: string;
}

const PrintBadges: React.FC<PrintBadgesProps> = ({ contacts, eventName }) => {
  return (
    <div className="print-container">
      {contacts.map((contact, index) => (
        <div key={index} className="badge">
          <div className="badge-header">
            <h1>{eventName}</h1>
          </div>
          <div className="badge-body">
            <p className="badge-name">{contact.name}</p>
            <p className="badge-company">{contact.organization}</p>
            <p className="badge-position">{contact.position}</p>
          </div>
          <hr className="badge-divider" />
          <div className="badge-ticket">
            <p>{contact.ticketType}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PrintBadges;
