import React, { useState, useEffect } from "react";
import { Switch, message, Card, Row, Col } from "antd";
import { getDocs, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";

const Settings = () => {
  const [publishCommittee, setPublishCommittee] = useState<boolean>(false);
  const [publishInformation, setPublishInformation] = useState<boolean>(false);
  const [publishSponsors, setPublishSponsors] = useState<boolean>(false);

  // Fetch current settings from Firestore (if you store them there)
  const fetchSettings = async () => {
    const settingsSnapshot = await getDocs(collection(db, "settings"));
    settingsSnapshot.docs.forEach((doc) => {
      const data = doc.data();
      setPublishCommittee(data.publishCommittee || false);
      setPublishInformation(data.publishInformation || false);
      setPublishSponsors(data.publishSponsors || false);
    });
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  // Handle toggle change and update Firestore
  const handleToggleChange = async (setting: string, value: boolean) => {
    try {
      const settingRef = doc(db, "settings", "settings");
      await updateDoc(settingRef, {
        [setting]: value,
      });
      message.success(`${setting} updated successfully!`);
    } catch (error) {
      console.error("Error updating settings:", error);
      message.error("Failed to update settings.");
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Settings</h1>
      <Row gutter={16}>
        <Col span={8}>
          <Card title="Publish Committee" bordered={false}>
            <Switch
              checked={publishCommittee}
              onChange={(checked) => {
                setPublishCommittee(checked);
                handleToggleChange("publishCommittee", checked);
              }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Publish Information" bordered={false}>
            <Switch
              checked={publishInformation}
              onChange={(checked) => {
                setPublishInformation(checked);
                handleToggleChange("publishInformation", checked);
              }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Publish Sponsors & Exhibitors" bordered={false}>
            <Switch
              checked={publishSponsors}
              onChange={(checked) => {
                setPublishSponsors(checked);
                handleToggleChange("publishSponsors", checked);
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
