import { FaBars } from "react-icons/fa";
import "./navigation.css";
import { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebaseConfig";

const Navigation = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [settings, setSettings] = useState({
    publishCommittee: false,
    publishInformation: false,
    publishSponsors: false,
  });

  useEffect(() => {
    // Fetch the current settings to determine if a section is published
    const fetchSettings = async () => {
      try {
        const settingsSnapshot = await getDocs(collection(db, "settings"));
        settingsSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          setSettings({
            publishCommittee: data.publishCommittee || false,
            publishInformation: data.publishInformation || false,
            publishSponsors: data.publishSponsors || false,
          });
        });
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  const handleScroll = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setMenuActive(false); // Close menu on navigation
    }
  };

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  // Conditional menu items based on publish settings
  const menuItems = [
    { id: "hero", label: "Home" },
    { id: "about", label: "About" },
    { id: "speakers", label: "Speakers" },
    settings.publishSponsors && {
      id: "sponsors",
      label: "Sponsors / Exhibitors",
    },
    { id: "agenda", label: "Agenda" },
    { id: "tickets", label: "Tickets" },
    { id: "partners", label: "Partners" },
    settings.publishCommittee && { id: "committee", label: "Committee" },
    settings.publishInformation && {
      id: "information",
      label: "Information",
    },
    { id: "footer", label: "Contact Us" },
  ].filter(Boolean); // Remove any false values (from unpublished items)

  return (
    <section className="hero overlay">
      <header className="header">
        <div className="container">
          <div className="header-row">
            {/* Logo Section */}
            <div className="logo-container">
              <img
                src="https://res.cloudinary.com/astcdubai/image/upload/v1736164746/45th_Ica_white_logo_xoggsq.png"
                alt="logo"
                className="logo-img"
              />
            </div>
            {/* Mobile Toggle Button */}
            <div className="toggle-mobile-but" onClick={toggleMenu}>
              <FaBars size={30} />
            </div>
            {/* Menu List */}
            <nav className="main-nav">
              <ul className={menuActive ? "active" : ""}>
                {menuItems.map((item: any) => (
                  <li
                    key={item.id}
                    onClick={() => handleScroll(item.id)}
                    style={{ cursor: "pointer" }}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </section>
  );
};

export default Navigation;
