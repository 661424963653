import { FaEnvelope, FaMobileAlt, FaPhoneAlt } from "react-icons/fa";
import "./footer.css";
import { useEffect, useState } from "react";

export default function Component() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };

    // Check screen size on initial load
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
    <div id="footer">
      {!isSmallScreen && (
        <section className="sc-dkzDqf eQXpkW footer">
          <div
            className="sc-bczRLJ bcVeZE"
            style={{
              boxSizing: "border-box",
              margin: "auto",
              width: "100%",
              maxWidth: "1300px",
            }}
          >
            <div
              className="sc-kngDgl fZvqKn"
              style={{
                boxSizing: "border-box",
                flexFlow: "wrap",
                display: "flex",
                WebkitBoxAlign: "center",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div
                className="sc-cwpsFg csTzrA"
                style={{
                  boxSizing: "border-box",
                  flex: "1 1 0%",
                  marginBottom: "15px",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  className="sc-gJwTLC bmsISO"
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    maxHeight: "700px",
                    position: "relative",
                  }}
                >
                  <img
                    className="sc-jKDlA-D fmYzei"
                    src="https://res.cloudinary.com/astcdubai/image/upload/v1736164746/45th_Ica_white_logo_xoggsq.png"
                    style={{
                      boxSizing: "border-box",
                      borderStyle: "none",
                      verticalAlign: "middle",
                      maxWidth: "60%",
                      WebkitBoxPack: "center",
                      justifyContent: "center",
                    }}
                  />
                </div>
              </div>
              <div
                className="sc-cwpsFg csTzrA"
                style={{
                  boxSizing: "border-box",
                  flex: "1 1 0%",
                  marginBottom: "15px",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  className="sc-fxvKuh elrGVm"
                  style={{
                    boxSizing: "border-box",
                    color: "#e6a73b",
                    fontSize: "16px",
                    lineHeight: "16px",
                    fontWeight: 700,
                    letterSpacing: "5px",
                    textTransform: "uppercase",
                    paddingBottom: "5px",
                  }}
                >
                  Social Media Links
                </div>
                <div
                  className="sc-tQuYZ inJAUa"
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    WebkitBoxPack: "center",
                    justifyContent: "center",
                    WebkitBoxAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    className="sc-hZFzCs bsuYmU"
                    aria-label="Facebook"
                    href="https://www.facebook.com/U.A.E.FMA"
                    target="_blank"
                    style={{
                      boxSizing: "border-box",
                      textDecoration: "none",
                      backgroundColor: "transparent",
                      paddingRight: "1rem",
                      color: "rgb(244, 244, 244)",
                      fontSize: "24px",
                    }}
                  >
                    <svg
                      height="1em"
                      width="1em"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        boxSizing: "border-box",
                        overflow: "hidden",
                        verticalAlign: "middle",
                      }}
                    >
                      <path
                        d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                        style={{ boxSizing: "border-box" }}
                      />
                    </svg>
                  </a>
                  <a
                    className="sc-hZFzCs bsuYmU"
                    aria-label="Instagram"
                    href="https://www.instagram.com/uaefma/"
                    target="_blank"
                    style={{
                      boxSizing: "border-box",
                      textDecoration: "none",
                      backgroundColor: "transparent",
                      paddingRight: "1rem",
                      color: "rgb(244, 244, 244)",
                      fontSize: "24px",
                    }}
                  >
                    <svg
                      height="1em"
                      width="1em"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 448 512"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        boxSizing: "border-box",
                        overflow: "hidden",
                        verticalAlign: "middle",
                      }}
                    >
                      <path
                        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                        style={{ boxSizing: "border-box" }}
                      />
                    </svg>
                  </a>
                  <a
                    className="sc-hZFzCs bsuYmU"
                    aria-label="Twitter"
                    href="https://twitter.com/UAEFMA?lang=en"
                    target="_blank"
                    style={{
                      boxSizing: "border-box",
                      textDecoration: "none",
                      backgroundColor: "transparent",
                      paddingRight: "1rem",
                      color: "rgb(244, 244, 244)",
                      fontSize: "24px",
                    }}
                  >
                    <svg
                      height="1em"
                      width="1em"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        boxSizing: "border-box",
                        overflow: "hidden",
                        verticalAlign: "middle",
                      }}
                    >
                      <path
                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                        style={{ boxSizing: "border-box" }}
                      />
                    </svg>
                  </a>
                  <a
                    className="sc-hZFzCs bsuYmU"
                    aria-label="LinkedIn"
                    href="https://www.linkedin.com/company/u-a-e-financial-markets-association?trk=company_logo"
                    target="_blank"
                    style={{
                      boxSizing: "border-box",
                      textDecoration: "none",
                      backgroundColor: "transparent",
                      paddingRight: "1rem",
                      color: "rgb(244, 244, 244)",
                      fontSize: "24px",
                    }}
                  >
                    <svg
                      height="1em"
                      width="1em"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 448 512"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        boxSizing: "border-box",
                        overflow: "hidden",
                        verticalAlign: "middle",
                      }}
                    >
                      <path
                        d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                        style={{ boxSizing: "border-box" }}
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div
                className="sc-cwpsFg csTzrA"
                style={{
                  boxSizing: "border-box",
                  flex: "1 1 0%",
                  marginBottom: "15px",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="sc-bTMaZy eQccYo"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    textAlign: "left",
                    width: "250px",
                    boxSizing: "border-box",
                    color: "rgb(244, 244, 244)",
                  }}
                >
                  <div
                    className="sc-fxvKuh elrGVm"
                    style={{
                      boxSizing: "border-box",
                      color: "#e6a73b",
                      fontSize: "16px",
                      lineHeight: "16px",
                      fontWeight: 700,
                      letterSpacing: "5px",
                      textTransform: "uppercase",
                      textAlign: "center",
                      paddingBottom: "5px",
                    }}
                  >
                    Contact Us
                  </div>
                  <span
                    className="sc-cHPgQl gmutez"
                    style={{
                      boxSizing: "border-box",
                      backgroundColor: "transparent",
                      textDecoration: "none",
                      color: "rgb(244, 244, 244)",
                      fontSize: "14px",
                      letterSpacing: "3px",
                      lineHeight: "2",
                    }}
                  >
                    <FaPhoneAlt size={12} /> : +971 4 256 6622
                  </span>
                  <span
                    className="sc-cHPgQl gmutez"
                    style={{
                      boxSizing: "border-box",
                      backgroundColor: "transparent",
                      textDecoration: "none",
                      color: "rgb(244, 244, 244)",
                      fontSize: "14px",
                      letterSpacing: "3px",
                      lineHeight: "2",
                    }}
                  >
                    <FaEnvelope size={12} /> : icadubai@uaefma.com
                  </span>
                  <span
                    className="sc-cHPgQl gmutez"
                    style={{
                      boxSizing: "border-box",
                      backgroundColor: "transparent",
                      textDecoration: "none",
                      color: "rgb(244, 244, 244)",
                      fontSize: "14px",
                      letterSpacing: "3px",
                      lineHeight: "2",
                    }}
                  >
                    <FaMobileAlt size={12} /> : +971 4 256 6622
                  </span>
                </div>
              </div>
            </div>
            <div
              className="sc-kngDgl fZvqKn"
              style={{
                boxSizing: "border-box",
                flexFlow: "wrap",
                display: "flex",
                WebkitBoxAlign: "center",
                alignItems: "center",
                justifyContent: "space-around",
                paddingTop: "50px",
              }}
            >
              <div
                className="sc-iWajrY dTXrsD"
                style={{
                  boxSizing: "border-box",
                  color: "rgb(244, 244, 244)",
                  fontSize: "12px",
                  letterSpacing: "3px",
                  textAlign: "center",
                }}
              >
                <svg
                  height="1em"
                  width="1em"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    boxSizing: "border-box",
                    overflow: "hidden",
                    verticalAlign: "middle",
                    paddingBottom: "2px",
                  }}
                >
                  <path
                    d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 448c-110.532 0-200-89.451-200-200 0-110.531 89.451-200 200-200 110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200zm107.351-101.064c-9.614 9.712-45.53 41.396-104.065 41.396-82.43 0-140.484-61.425-140.484-141.567 0-79.152 60.275-139.401 139.762-139.401 55.531 0 88.738 26.62 97.593 34.779a11.965 11.965 0 0 1 1.936 15.322l-18.155 28.113c-3.841 5.95-11.966 7.282-17.499 2.921-8.595-6.776-31.814-22.538-61.708-22.538-48.303 0-77.916 35.33-77.916 80.082 0 41.589 26.888 83.692 78.277 83.692 32.657 0 56.843-19.039 65.726-27.225 5.27-4.857 13.596-4.039 17.82 1.738l19.865 27.17a11.947 11.947 0 0 1-1.152 15.518z"
                    style={{ boxSizing: "border-box" }}
                  />
                </svg>
                 48
                <sup
                  style={{
                    boxSizing: "border-box",
                    position: "relative",
                    fontSize: "75%",
                    lineHeight: 0,
                    verticalAlign: "baseline",
                    top: "-0.5em",
                  }}
                >
                  th
                </sup>{" "}
                ICA CONFERENCE & EXHIBITION . ALL RIGHTS RESERVED
              </div>
            </div>
          </div>
        </section>
      )}

      {isSmallScreen && (
        <section className="sc-dkzDqf eQXpkW footer">
          <div
            className="sc-bczRLJ bcVeZE"
            style={{
              boxSizing: "border-box",
              margin: "auto",
              width: "100%",
              maxWidth: "1300px",
              justifyContent: "flex-start",
            }}
          >
            <div
              className="sc-cwpsFg csTzrA"
              style={{
                boxSizing: "border-box",
                flex: "1 1 0%",
                display: "flex",
                flexDirection: "column",
                marginLeft: 30,
              }}
            >
              <div
                className="sc-bTMaZy eQccYo"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  textAlign: "left",
                  marginBottom: 20,
                }}
              >
                <div
                  className="sc-fxvKuh elrGVm"
                  style={{
                    boxSizing: "border-box",
                    color: "#e6a73b",
                    fontSize: "16px",
                    lineHeight: "16px",
                    fontWeight: 700,
                    letterSpacing: "5px",
                    textTransform: "uppercase",
                    paddingBottom: "5px",
                  }}
                >
                  Social Media Links
                </div>
                <div
                  className="sc-tQuYZ inJAUa"
                  style={{
                    boxSizing: "border-box",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <a
                    className="sc-hZFzCs bsuYmU"
                    aria-label="Facebook"
                    href="https://www.facebook.com/U.A.E.FMA"
                    target="_blank"
                    style={{
                      boxSizing: "border-box",
                      textDecoration: "none",
                      backgroundColor: "transparent",
                      paddingRight: "1rem",
                      color: "rgb(244, 244, 244)",
                      fontSize: "24px",
                    }}
                  >
                    <svg
                      height="1em"
                      width="1em"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        boxSizing: "border-box",
                        overflow: "hidden",
                        verticalAlign: "middle",
                      }}
                    >
                      <path
                        d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                        style={{ boxSizing: "border-box" }}
                      />
                    </svg>
                  </a>
                  <a
                    className="sc-hZFzCs bsuYmU"
                    aria-label="Instagram"
                    href="https://www.instagram.com/uaefma/"
                    target="_blank"
                    style={{
                      boxSizing: "border-box",
                      textDecoration: "none",
                      backgroundColor: "transparent",
                      paddingRight: "1rem",
                      color: "rgb(244, 244, 244)",
                      fontSize: "24px",
                    }}
                  >
                    <svg
                      height="1em"
                      width="1em"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 448 512"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        boxSizing: "border-box",
                        overflow: "hidden",
                        verticalAlign: "middle",
                      }}
                    >
                      <path
                        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                        style={{ boxSizing: "border-box" }}
                      />
                    </svg>
                  </a>
                  <a
                    className="sc-hZFzCs bsuYmU"
                    aria-label="Twitter"
                    href="https://twitter.com/UAEFMA?lang=en"
                    target="_blank"
                    style={{
                      boxSizing: "border-box",
                      textDecoration: "none",
                      backgroundColor: "transparent",
                      paddingRight: "1rem",
                      color: "rgb(244, 244, 244)",
                      fontSize: "24px",
                    }}
                  >
                    <svg
                      height="1em"
                      width="1em"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        boxSizing: "border-box",
                        overflow: "hidden",
                        verticalAlign: "middle",
                      }}
                    >
                      <path
                        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                        style={{ boxSizing: "border-box" }}
                      />
                    </svg>
                  </a>
                  <a
                    className="sc-hZFzCs bsuYmU"
                    aria-label="LinkedIn"
                    href="https://www.linkedin.com/company/u-a-e-financial-markets-association?trk=company_logo"
                    target="_blank"
                    style={{
                      boxSizing: "border-box",
                      textDecoration: "none",
                      backgroundColor: "transparent",
                      paddingRight: "1rem",
                      color: "rgb(244, 244, 244)",
                      fontSize: "24px",
                    }}
                  >
                    <svg
                      height="1em"
                      width="1em"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 448 512"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        boxSizing: "border-box",
                        overflow: "hidden",
                        verticalAlign: "middle",
                      }}
                    >
                      <path
                        d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                        style={{ boxSizing: "border-box" }}
                      />
                    </svg>
                  </a>
                </div>
              </div>

              <div
                className="sc-cwpsFg csTzrA"
                style={{
                  boxSizing: "border-box",
                  flex: "1 1 0%",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                }}
              >
                <div
                  className="sc-bTMaZy eQccYo"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    textAlign: "left",
                    marginBottom: 20,
                  }}
                >
                  <div
                    className="sc-fxvKuh elrGVm"
                    style={{
                      boxSizing: "border-box",
                      color: "#e6a73b",
                      fontSize: "16px",
                      lineHeight: "16px",
                      fontWeight: 700,
                      letterSpacing: "5px",
                      textTransform: "uppercase",
                      textAlign: "center",
                      paddingBottom: "5px",
                    }}
                  >
                    Contact Us
                  </div>
                  <span
                    className="sc-cHPgQl gmutez"
                    style={{
                      boxSizing: "border-box",
                      backgroundColor: "transparent",
                      textDecoration: "none",
                      color: "rgb(244, 244, 244)",
                      fontSize: "14px",
                      letterSpacing: "3px",
                      lineHeight: "2",
                    }}
                  >
                    <FaPhoneAlt size={12} /> : +971 4 256 6622
                  </span>
                  <span
                    className="sc-cHPgQl gmutez"
                    style={{
                      boxSizing: "border-box",
                      backgroundColor: "transparent",
                      textDecoration: "none",
                      color: "rgb(244, 244, 244)",
                      fontSize: "14px",
                      letterSpacing: "3px",
                      lineHeight: "2",
                    }}
                  >
                    <FaEnvelope size={12} /> : icadubai@uaefma.com
                  </span>
                  <span
                    className="sc-cHPgQl gmutez"
                    style={{
                      boxSizing: "border-box",
                      backgroundColor: "transparent",
                      textDecoration: "none",
                      color: "rgb(244, 244, 244)",
                      fontSize: "14px",
                      letterSpacing: "3px",
                      lineHeight: "2",
                    }}
                  >
                    <FaMobileAlt size={12} /> : +971 4 256 6622
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div
                className="sc-gJwTLC bmsISO"
                style={{
                  boxSizing: "border-box",
                  display: "flex",
                  maxHeight: "700px",
                  position: "relative",
                  marginLeft: 30,
                }}
              >
                <img
                  className="sc-jKDlA-D fmYzei"
                  src="https://res.cloudinary.com/astcdubai/image/upload/v1736164746/45th_Ica_white_logo_xoggsq.png"
                  style={{
                    boxSizing: "border-box",
                    borderStyle: "none",
                    maxWidth: "35%",
                  }}
                />
              </div>
            </div>
            <div
              className="sc-kngDgl fZvqKn"
              style={{
                boxSizing: "border-box",
                flexFlow: "wrap",
                display: "flex",
                WebkitBoxAlign: "center",
                justifyContent: "space-around",
                paddingTop: "50px",
              }}
            >
              <div
                className="sc-iWajrY dTXrsD"
                style={{
                  boxSizing: "border-box",
                  color: "rgb(244, 244, 244)",
                  fontSize: "11px",
                  letterSpacing: "3px",
                }}
              >
                <svg
                  height="1em"
                  width="1em"
                  fill="currentColor"
                  stroke="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    boxSizing: "border-box",
                    overflow: "hidden",
                    verticalAlign: "middle",
                    paddingBottom: "2px",
                  }}
                >
                  <path
                    d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 448c-110.532 0-200-89.451-200-200 0-110.531 89.451-200 200-200 110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200zm107.351-101.064c-9.614 9.712-45.53 41.396-104.065 41.396-82.43 0-140.484-61.425-140.484-141.567 0-79.152 60.275-139.401 139.762-139.401 55.531 0 88.738 26.62 97.593 34.779a11.965 11.965 0 0 1 1.936 15.322l-18.155 28.113c-3.841 5.95-11.966 7.282-17.499 2.921-8.595-6.776-31.814-22.538-61.708-22.538-48.303 0-77.916 35.33-77.916 80.082 0 41.589 26.888 83.692 78.277 83.692 32.657 0 56.843-19.039 65.726-27.225 5.27-4.857 13.596-4.039 17.82 1.738l19.865 27.17a11.947 11.947 0 0 1-1.152 15.518z"
                    style={{ boxSizing: "border-box" }}
                  />
                </svg>
                 48
                <sup
                  style={{
                    boxSizing: "border-box",
                    position: "relative",
                    fontSize: "60%",
                    lineHeight: 0,
                    verticalAlign: "baseline",
                    top: "-0.5em",
                  }}
                >
                  th
                </sup>{" "}
                {!isSmallScreen ? (
                  <span>ICA CONFERENCE & EXHIBITION . ALL RIGHTS RESERVED</span>
                ) : (
                  <>
                    <span>ICA CONFERENCE & EXHIBITION </span>
                    <br />
                    <span>ALL RIGHTS RESERVED</span>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
      </div>
    </>
  );
}
