import React, { useContext, useState, useEffect } from "react";
import { CartContext } from "./context/CartContext";
import { addDoc, collection, db } from "../firebaseConfig";
import { getDocs } from "firebase/firestore";
import { message } from "antd";
import PhoneInput from "react-phone-number-input";
import "./orders.css";
import "react-phone-number-input/style.css"; // import the styles

// Define the shape for a participant
type Participants = {
  name: string;
  email: string;
  organization: string;
  position: string;
  mobileNumber: string;
  ticketType: string;
};

interface OrdersV2ModalProps {
  isOpen: boolean;
  onClose: () => void;
  setSnackbar: (snackbar: {
    open: boolean;
    message: string;
    severity: "success" | "error" | "info" | "warning";
  }) => void;
}

const OrdersV2Modal: React.FC<OrdersV2ModalProps> = ({
  isOpen,
  onClose,
  setSnackbar,
}) => {
  const { cartItems, updateQuantity, clearCart } = useContext(CartContext);
  const [coupon, setCoupon] = useState<string>("");
  const [discount, setDiscount] = useState<number>(0);
  const [couponAlert, setCouponAlert] = useState<string>("");
  const [coupons, setCoupons] = useState<
    { code: string; discount: number; discountType: string; active: boolean }[]
  >([]);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [totalDelegateTickets, setTotalDelegateTickets] = useState<number>(0);
  const [totalUaefmaTickets, setTotalUaefmaTickets] = useState<number>(0);

  // Participants array will have one entry per ticket selected
  const [participants, setParticipants] = useState<Participants[]>([]);
  const [currentPersonIndex, setCurrentPersonIndex] = useState<number>(0);

  // Define the fixed ticket prices
  const TICKET_PRICES: { [key: string]: number } = {
    Delegate: 1950,
    "UAEFMA Member": 950,
  };

  // Handle responsiveness
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Sync participants with the quantity selected in the cart.
  // (We assume the cart quantity now only drives the number of participants.)
  useEffect(() => {
    const totalQuantity = cartItems.reduce(
      (sum, item) => sum + item.quantity,
      0
    );
    setParticipants((prev) => {
      if (totalQuantity > prev.length) {
        return [
          ...prev,
          ...Array(totalQuantity - prev.length).fill({
            name: "",
            email: "",
            organization: "",
            position: "",
            mobileNumber: "",
            ticketType: "",
          }),
        ];
      } else {
        return prev.slice(0, totalQuantity);
      }
    });
  }, [cartItems]);

  // Fetch available coupons from Firebase
  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const couponCollectionRef = collection(db, "coupons");
        const couponSnapshot = await getDocs(couponCollectionRef);
        const couponList = couponSnapshot.docs.map((doc) => ({
          code: doc.data().code,
          discount: doc.data().discount,
          discountType: doc.data().discountType,
          active: doc.data().active,
        }));
        setCoupons(couponList);
      } catch (error) {
        console.error("Error fetching coupons:", error);
      }
    };

    fetchCoupons();
  }, []);

  // Reset coupon discount if coupon input is cleared
  useEffect(() => {
    if (coupon === "") {
      setDiscount(0);
      setCouponAlert("");
    }
  }, [coupon]);

  // Update ticket counts based on each participant’s selected ticket type
  useEffect(() => {
    const delegateCount = participants.filter(
      (p) => p.ticketType === "Delegate"
    ).length;
    const uaefmaCount = participants.filter(
      (p) => p.ticketType === "UAEFMA Member"
    ).length;
    setTotalDelegateTickets(delegateCount);
    setTotalUaefmaTickets(uaefmaCount);
  }, [participants]);

  // Calculate the total amount based on ticket type selection.
  // If a participant hasn't yet selected a ticket type, that entry adds $0.
  const totalAmount = participants.reduce((acc, participant) => {
    return acc + (TICKET_PRICES[participant.ticketType] || 0);
  }, 0);

  // Calculate total after applying coupon discount (if any)
  const totalWithDiscount = couponAlert.includes("off")
    ? (
        totalAmount -
        (couponAlert.includes("%") ? (totalAmount * discount) / 100 : discount)
      ).toFixed(2)
    : totalAmount.toFixed(2);

  // Coupon apply function (unchanged)
  const applyCoupon = () => {
    const couponFound = coupons.find((c) => c.code === coupon && c.active);
    if (couponFound) {
      if (couponFound.discountType === "percentage") {
        setDiscount(couponFound.discount);
        setCouponAlert(`Coupon applied: ${couponFound.discount}% off!`);
      } else if (couponFound.discountType === "exact") {
        setDiscount(couponFound.discount);
        setCouponAlert(`Coupon applied: $${couponFound.discount} off!`);
      }
    } else {
      setDiscount(0);
      setCouponAlert("Invalid coupon code");
    }
  };

  // When submitting the order, ensure that the current participant’s fields are filled.
  // (You might also consider looping over all participants for validation.)
  const handleOrder = async () => {
    if (participants.length === 0) {
      setSnackbar({
        open: true,
        message: "You have not selected any tickets",
        severity: "error",
      });
      return;
    }

    if (
      participants[currentPersonIndex].name === "" ||
      participants[currentPersonIndex].email === "" ||
      participants[currentPersonIndex].organization === "" ||
      participants[currentPersonIndex].position === "" ||
      participants[currentPersonIndex].mobileNumber === "" ||
      participants[currentPersonIndex].ticketType === ""
    ) {
      message.error("Please fill out all the fields!");
      return;
    }

    if (!/\S+@\S+\.\S+/.test(participants[currentPersonIndex].email)) {
      message.error("Email is invalid");
      return;
    }

    // Order data includes counts per ticket type and the computed total
    const orderData = {
      totalDelegateTickets,
      totalUaefmaTickets,
      coupon,
      totalAmount: totalWithDiscount,
      timestamp: new Date(),
    };

    try {
      // Save the main order document
      const orderRef = await addDoc(collection(db, "orders"), orderData);

      // Save each participant as a subcollection entry under the order
      const contactsCollectionRef = collection(
        db,
        `orders/${orderRef.id}/contacts`
      );

      await Promise.all(
        participants.map((person) =>
          addDoc(contactsCollectionRef, {
            ...person,
            ticketType: person.ticketType,
          })
        )
      );

      setSnackbar({
        open: true,
        message: "Registration has been successful!",
        severity: "success",
      });

      setTimeout(
        () => setSnackbar({ open: false, message: "", severity: "success" }),
        3000
      );

      clearCart();
      setCoupon("");
      setCouponAlert("");
      setParticipants([]);
      onClose();
    } catch (error: any) {
      setSnackbar({
        open: true,
        message: `There was an issue placing your order: ${
          error.message || "Unknown error"
        }`,
        severity: "error",
      });
      console.error("Error adding documents: ", error);
    }
  };

  // Simple validation for the current participant before moving to the next
  const handleValidation = () => {
    if (
      participants[currentPersonIndex].name === "" ||
      participants[currentPersonIndex].email === "" ||
      participants[currentPersonIndex].organization === "" ||
      participants[currentPersonIndex].position === "" ||
      participants[currentPersonIndex].mobileNumber === "" ||
      participants[currentPersonIndex].ticketType === ""
    ) {
      message.error("Please fill out all the fields!");
      return;
    }

    if (!/\S+@\S+\.\S+/.test(participants[currentPersonIndex].email)) {
      message.error("Email is invalid");
      return;
    }

    // const cleanedNumber = participants[currentPersonIndex].mobileNumber.replace(
    //   /\D/g,
    //   ""
    // ); // \D matches anything that's not a digit

    // if (cleanedNumber.length !== 11 && !/^\d{11}$/.test(cleanedNumber)) {
    //   message.error("Phone number must be valid and exactly 11 digits.");
    //   return;
    // }

    setCurrentPersonIndex((prev) => prev + 1);
  };

  const handlePhoneNumber = (value: string | undefined) => {
    const newParticipants = [...participants];
    if (value !== undefined) {
      newParticipants[currentPersonIndex].mobileNumber = value;
      setParticipants(newParticipants);
    }
  };

  // Total number of tickets selected (for enabling/disabling coupon field)
  const totalTickets = cartItems.reduce((sum, item) => sum + item.quantity, 0);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-header">
          <h1 className="title">Tickets</h1>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>

        <div className="modal-body">
          <div className="cart-items-column">
            <h3 className="pb-10">Summary</h3>
            {cartItems.length === 0 ? (
              <p>Your cart is empty.</p>
            ) : (
              <ul className="cart-list-group">
                <li className="cart-list-group-item">
                  <div
                    className="d-flex align-items-center"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span>Quantity</span>
                    <div className="quantity-controls">
                      <button
                        className="btn btn-xs btn-secondary"
                        onClick={() =>
                          updateQuantity(cartItems[0]?.id, "decrement")
                        }
                        disabled={
                          cartItems.reduce(
                            (acc, item) => acc + item.quantity,
                            0
                          ) === 1
                        }
                      >
                        -
                      </button>
                      &nbsp;
                      <span className="mx-3">
                        {cartItems.reduce(
                          (acc, item) => acc + item.quantity,
                          0
                        )}
                      </span>
                      &nbsp;
                      <button
                        className="btn btn-xs btn-secondary"
                        onClick={() =>
                          updateQuantity(cartItems[0]?.id, "increment")
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            )}

            {/* Coupon Input Section */}
            <div className="coupon-section pt-5">
              <input
                type="text"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                placeholder="Enter coupon code"
                style={{ width: isSmallScreen ? 175 : 260, marginRight: 10 }}
                disabled={totalTickets === 0}
              />
              <button
                disabled={totalTickets === 0}
                className="btn-register"
                onClick={applyCoupon}
              >
                Apply Coupon
              </button>
              <span className="pt-0">{couponAlert ?? ""}</span>
            </div>

            <div
              className="coupon-section"
              style={{ position: "absolute", bottom: 0 }}
            >
              <p className="small">
                Please contact us for group registration for discounted rates.
              </p>
            </div>
          </div>

          {participants.length > 0 && (
            <div className="personal-info-column">
              <h3>
                Participant{" "}
                {currentPersonIndex === 0 ? 1 : currentPersonIndex + 1} of{" "}
                {participants.length}
              </h3>
              <p className="small pb-10">
                We will send payment instructions via email.
              </p>

              <h4>Select Ticket Type</h4>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  flexDirection: "row",
                  marginTop: 5,
                  marginBottom: 10,
                }}
              >
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    padding: "8px 12px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    border:
                      participants[currentPersonIndex]?.ticketType ===
                      "Delegate"
                        ? "1px solid #DF972E"
                        : "1px solid #8C8C8C",
                    color:
                      participants[currentPersonIndex]?.ticketType ===
                      "Delegate"
                        ? "#CCC"
                        : "#8C8C8C",
                    transition: "all 0.3s ease",
                  }}
                >
                  <input
                    type="radio"
                    name={`ticketType-${currentPersonIndex}`}
                    value="Delegate"
                    checked={
                      participants[currentPersonIndex]?.ticketType ===
                      "Delegate"
                    }
                    onChange={(e) => {
                      const newParticipants = [...participants];
                      newParticipants[currentPersonIndex].ticketType =
                        e.target.value;
                      setParticipants(newParticipants);
                    }}
                    style={{ display: "none" }}
                  />
                  Delegate
                </label>

                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    padding: "8px 12px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    border:
                      participants[currentPersonIndex]?.ticketType ===
                      "UAEFMA Member"
                        ? "1px solid #DF972E"
                        : "1px solid #8C8C8C",
                    color:
                      participants[currentPersonIndex]?.ticketType ===
                      "UAEFMA Member"
                        ? "#CCC"
                        : "#8C8C8C",
                    transition: "all 0.3s ease",
                  }}
                >
                  <input
                    type="radio"
                    name={`ticketType-${currentPersonIndex}`}
                    value="UAEFMA Member"
                    checked={
                      participants[currentPersonIndex]?.ticketType ===
                      "UAEFMA Member"
                    }
                    onChange={(e) => {
                      const newParticipants = [...participants];
                      newParticipants[currentPersonIndex].ticketType =
                        e.target.value;
                      setParticipants(newParticipants);
                    }}
                    style={{ display: "none" }}
                  />
                  UAEFMA Member
                </label>
              </div>

              <input
                type="text"
                placeholder="Name"
                value={participants[currentPersonIndex]?.name || ""}
                onChange={(e) => {
                  const newParticipants = [...participants];
                  newParticipants[currentPersonIndex].name = e.target.value;
                  setParticipants(newParticipants);
                }}
              />
              <input
                type="text"
                placeholder="Email"
                value={participants[currentPersonIndex]?.email || ""}
                onChange={(e) => {
                  const newParticipants = [...participants];
                  newParticipants[currentPersonIndex].email = e.target.value;
                  setParticipants(newParticipants);
                }}
              />
              <input
                type="text"
                placeholder="Organization"
                value={participants[currentPersonIndex]?.organization || ""}
                onChange={(e) => {
                  const newParticipants = [...participants];
                  newParticipants[currentPersonIndex].organization =
                    e.target.value;
                  setParticipants(newParticipants);
                }}
              />
              <input
                type="text"
                placeholder="Position"
                value={participants[currentPersonIndex]?.position || ""}
                onChange={(e) => {
                  const newParticipants = [...participants];
                  newParticipants[currentPersonIndex].position = e.target.value;
                  setParticipants(newParticipants);
                }}
              />
              <div style={{ paddingRight: 5 }}>
                <PhoneInput
                  type="text"
                  placeholder="Mobile Number (e.g +971 xx xxx xxxx)"
                  value={participants[currentPersonIndex]?.mobileNumber || ""}
                  international
                  defaultCountry="AE"
                  onChange={handlePhoneNumber}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: 5,
                }}
              >
                <button
                  disabled={totalTickets === 1 || currentPersonIndex === 0}
                  onClick={() => setCurrentPersonIndex((prev) => prev - 1)}
                  className="btn-register"
                >
                  Back
                </button>
                <button
                  disabled={
                    totalTickets === 1 ||
                    currentPersonIndex === participants.length - 1
                  }
                  onClick={handleValidation}
                  className="btn-register"
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>

        <div
          className="pt-20"
          style={{
            justifyContent: "space-between",
            display: "flex",
            textAlign: "center",
            borderTop: "1px solid #fff",
          }}
        >
          {participants.length > 0 && (
            <div className="mt-4">
              <h4>Total: ${totalWithDiscount}</h4>
            </div>
          )}
          <button
            className={`btn-register ${
              cartItems.length === 0 || couponAlert.includes("Invalid")
                ? "disabled"
                : ""
            }`}
            onClick={handleOrder}
            disabled={cartItems.length === 0 || couponAlert.includes("Invalid")}
          >
            Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrdersV2Modal;
