import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { FaAngleLeft, FaCog, FaInfoCircle, FaPeopleArrows, FaQuestion, FaTable, FaTags, FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ManageCoupon from "./ManageCoupons";
import ManageRegistrations from "./ManageRegistrations";
import ManageSpeakers from "./ManageSpeakers";
import ManageCommittees from "./ManageCommittee";
import ManageAccommodationAndTravel from "./ManageAccommodationAndTravel";
import Settings from "./Settings";
import ManageFAQs from "./ManageFAQ";

const { Sider, Content } = Layout;

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState<string>("orders");

  useEffect(() => {
    // Check if the user is authenticated
    const isAuthenticated = localStorage.getItem("isAuthenticated");

    if (!isAuthenticated) {
      // If not authenticated, redirect to the login page
      navigate("/login");
    }
  }, [navigate]);

  const handleLogout = () => {
    // Clear authentication state (e.g., remove the token)
    localStorage.removeItem("isAuthenticated");

    // Redirect to the login page
    navigate("/login");
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/* Sidebar */}
      <Sider width={275}>
        <Menu
          mode="inline"
          defaultSelectedKeys={["orders"]}
          style={{
            height: "100%",
            borderRight: 0,
            padding: 20,
            background: "#EFF3F4",
          }}
        >
          <Menu.Item
            key="orders"
            icon={<FaTable />}
            onClick={() => setCurrentView("orders")}
            style={{
              color: currentView === "orders" ? "#0900DC" : "#0E131D",
              background: currentView === "orders" ? "#EBECFD" : "transparent",
              border: currentView === "orders" ? "1px solid #B3B4F5" : "none",
            }}
          >
            Registrations
          </Menu.Item>
          <Menu.Item
            key="coupons"
            icon={<FaTags />}
            onClick={() => setCurrentView("coupons")}
            style={{
              color: currentView === "coupons" ? "#0900DC" : "#0E131D",
              background: currentView === "coupons" ? "#EBECFD" : "transparent",
              border: currentView === "coupons" ? "1px solid #B3B4F5" : "none",
            }}
          >
            Coupons
          </Menu.Item>
          <Menu.Item
            key="speakers"
            icon={<FaUsers />}
            onClick={() => setCurrentView("speakers")}
            style={{
              color: currentView === "speakers" ? "#0900DC" : "#0E131D",
              background: currentView === "speakers" ? "#EBECFD" : "transparent",
              border: currentView === "speakers" ? "1px solid #B3B4F5" : "none",
            }}
          >
            Speakers
          </Menu.Item>
          <Menu.Item
            key="committees"
            icon={<FaPeopleArrows />}
            onClick={() => setCurrentView("committees")}
            style={{
              color: currentView === "committees" ? "#0900DC" : "#0E131D",
              background: currentView === "committees" ? "#EBECFD" : "transparent",
              border: currentView === "committees" ? "1px solid #B3B4F5" : "none",
            }}
          >
            Committees
          </Menu.Item>
          <Menu.Item
            key="accommodation"
            icon={<FaInfoCircle />}
            onClick={() => setCurrentView("accommodation")}
            style={{
              color: currentView === "accommodation" ? "#0900DC" : "#0E131D",
              background: currentView === "accommodation" ? "#EBECFD" : "transparent",
              border: currentView === "accommodation" ? "1px solid #B3B4F5" : "none",
            }}
          >
            Accommodation & Travel
          </Menu.Item>
          <Menu.Item
            key="faqs"
            icon={<FaQuestion />}
            onClick={() => setCurrentView("faqs")}
            style={{
              color: currentView === "faqs" ? "#0900DC" : "#0E131D",
              background: currentView === "faqs" ? "#EBECFD" : "transparent",
              border: currentView === "faqs" ? "1px solid #B3B4F5" : "none",
            }}
          >
            FAQs
          </Menu.Item>
          <Menu.Item
            key="settings"
            icon={<FaCog />}
            onClick={() => setCurrentView("settings")}
            style={{
              color: currentView === "settings" ? "#0900DC" : "#0E131D",
              background: currentView === "settings" ? "#EBECFD" : "transparent",
              border: currentView === "settings" ? "1px solid #B3B4F5" : "none",
            }}
          >
            Settings
          </Menu.Item>
          <Menu.Item
            key="logout"
            icon={<FaAngleLeft />}
            style={{
              position: "absolute",
              bottom: 20,
              left: 0,
              width: "100%",
              background: "transparent",
              color: "#0900DC",
              borderTop: "1px solid #ddd",
              paddingLeft: 75,
            }}
            onClick={handleLogout}
          >
            Logout
          </Menu.Item>
        </Menu>
      </Sider>

      {/* Main Content */}
      <Layout>
        <Content style={{ padding: "20px", background: "#fff" }}>
          {currentView === "orders" && <ManageRegistrations />}
          {currentView === "coupons" && <ManageCoupon />}
          {currentView === "speakers" && <ManageSpeakers />}
          {currentView === "committees" && <ManageCommittees />}
          {currentView === "accommodation" && <ManageAccommodationAndTravel />}
          {currentView === "faqs" && <ManageFAQs />}
          {currentView === "settings" && <Settings />}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
